import React from 'react';
import { PaperNoIcon } from '../Paper';
import { ControlType, DesignSelections } from '../../types';

type Props = {
    designSelections: DesignSelections;
    setDesignSelections: React.Dispatch<React.SetStateAction<DesignSelections>>;
}

const ControlTypeCard = (props: Props): React.ReactElement => {

    const handleClick = (controlType: ControlType): void => {
        var newDesignSelections = { ...props.designSelections };
        newDesignSelections.controlType = controlType;
        props.setDesignSelections(newDesignSelections);
    }

    return (
        <div>
            <h2 className="text-center pb-3">Control Type</h2>
            <div className="d-flex flex-wrap justify-content-center">
                <PaperNoIcon disabled={!props.designSelections.availableControlTypes.includes(ControlType.TrailingEdge)} selected={props.designSelections.controlType === ControlType.TrailingEdge} onClick={() => handleClick(ControlType.TrailingEdge)} header="Trailing Edge" />
                <PaperNoIcon disabled={!props.designSelections.availableControlTypes.includes(ControlType.LeadingEdge)} selected={props.designSelections.controlType === ControlType.LeadingEdge} onClick={() => handleClick(ControlType.LeadingEdge)} header="Leading Edge" />
                <PaperNoIcon disabled={!props.designSelections.availableControlTypes.includes(ControlType.V1to10)} selected={props.designSelections.controlType === ControlType.V1to10} onClick={() => handleClick(ControlType.V1to10)} header="1-10v" />
            </div>
            <div className="d-flex flex-wrap justify-content-center">
                <PaperNoIcon disabled={!props.designSelections.availableControlTypes.includes(ControlType.DALI)} selected={props.designSelections.controlType === ControlType.DALI} onClick={() => handleClick(ControlType.DALI)} header="DALI" />
            </div>
        </div>
    );
}

export default ControlTypeCard;
