import React from 'react';
import { Box, Slider } from '@mui/material';
import { DesignSelections } from '../../types';

type Props = {
    designSelections: DesignSelections;
    setDesignSelections: React.Dispatch<React.SetStateAction<DesignSelections>>;
}

const CCTDynamicTypeCard = (props: Props): React.ReactElement => {

    const setValues = (values: number[]): void => {
        var newDesignSelections = { ...props.designSelections };
        newDesignSelections.cctDynamicType = values;
        props.setDesignSelections(newDesignSelections);
    }

    const marks = [
        {
            value: 2700,
            label: '2700K',
        },
        {
            value: 3000,
            label: '3000K',
        },
        {
            value: 3500,
            label: '3500K',
        },
        {
            value: 4000,
            label: '4000K',
        },
        {
            value: 5000,
            label: '5000K',
        },
    ];

    const minDistance = 300;

    const [value2, setValue2] = React.useState([2700, 5000]);

    const handleChange2 = (
        event: Event,
        newValue: number | number[],
        activeThumb: number,
    ) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (newValue[1] - newValue[0] < minDistance) {
            if (activeThumb === 0) {
                const clamped = Math.min(newValue[0], 100 - minDistance);
                setValue2([clamped, clamped + minDistance]);
            } else {
                const clamped = Math.max(newValue[1], minDistance);
                setValue2([clamped - minDistance, clamped]);
            }
        } else {
            setValue2(newValue as number[]);
        }

        setValues(newValue);
    };

    return (
        <div>
            <h2 className="text-center pb-3">Correlated Colour Temperature</h2>
            <div className="d-flex flex-wrap justify-content-center pl-4 pr-4">
                <Box sx={{ width: 500 }}>
                    <Slider
                        getAriaLabel={() => 'Minimum distance shift'}
                        valueLabelDisplay="auto"
                        disableSwap
                        onChange={handleChange2}
                        value={value2}
                        marks={marks}
                        min={2700}
                        max={5000}
                    />
                </Box>
            </div>
        </div>
    );
}

export default CCTDynamicTypeCard;