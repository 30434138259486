import React from 'react';
import { PaperNoIcon } from '../Paper';
import { DesignSelections, HousingFinishType } from '../../types';

type Props = {
    designSelections: DesignSelections;
    setDesignSelections: React.Dispatch<React.SetStateAction<DesignSelections>>;
}

const HousingFinishCard = (props: Props): React.ReactElement => {

    const handleClick = (housingFinish: HousingFinishType): void => {
        var newDesignSelections = { ...props.designSelections };
        newDesignSelections.housingFinishType = housingFinish;
        props.setDesignSelections(newDesignSelections);
    }

    return (
        <div>
            <h2 className="text-center pb-3">Housing Finish</h2>
            <div className="d-flex flex-wrap justify-content-center">
                <PaperNoIcon disabled={false} selected={props.designSelections.housingFinishType === HousingFinishType.Any} onClick={() => handleClick(HousingFinishType.Any)} header="Any" />
                <PaperNoIcon disabled={!props.designSelections.availableHousingFinishes.includes(HousingFinishType.Aluminium)} selected={props.designSelections.housingFinishType === HousingFinishType.Aluminium} onClick={() => handleClick(HousingFinishType.Aluminium)} header="Aluminium" />
            </div>
        </div>
    );
}

export default HousingFinishCard;
