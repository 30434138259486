import React, { useEffect, useState } from 'react';
import { BuildingType, CardType, CCTTemperatureType, CCTType, CommercialOfficeType, ControlType, DesignSelectionClient, DesignSelections, HousingColourType, HousingFinishType, LanguageType, ProductType, RecessedSizeType, ProjectResult } from '../types';
import LanguageTypeCard from './cards/LanguageTypeCard';
import ProjectTypeCard from './cards/ProjectTypeCard';
import BuildingTypeCard from './cards/BuildingTypeCard';
import CommercialOfficeTypeCard from './cards/CommercialOfficeTypeCard';
import ProductTypeCard from './cards/ProductTypeCard';
import RecessedSizeTypeCard from './cards/RecessedSizeTypeCard';
import ControlTypeCard from './cards/ControlTypeCard';
import CCTTypeCard from './cards/CCTTypeCard';
import CCTTemperatureTypeCard from './cards/CCTTemperatureTypeCard';
import CCTDynamicTypeCard from './cards/CCTDynamicTypeCard';
import HousingColourCard from './cards/HousingColourCard';
import HousingFinishCard from './cards/HousingFinishCard';
import RoomDimensionCard from './cards/RoomDimensionCard';
import ResultsCard from './cards/ResultsCard';
import ResultSelectionCard from './cards/ResultSelectionCard';
import { Button } from '@mui/material';

const DefaultDesignSelections: DesignSelections = {
    languageType: LanguageType.None,
    selection: CardType.LanguageType,
    buildingType: BuildingType.None,
    commercialOfficeType: CommercialOfficeType.None,
    productType: ProductType.None,
    recessedSizeType: RecessedSizeType.None,
    controlType: ControlType.None,
    cctType: CCTType.None,
    cctTemperatureType: CCTTemperatureType.None,
    housingColourType: HousingColourType.None,
    housingFinishType: HousingFinishType.None,
    height: 0,
    width: 0,
    length: 0,
    roomProperties: {
        reflectanceCeiling: 70,
        reflectanceWall: 50,
        reflectanceFloor: 20,
        targetIlluminance: 500,
        ceilingDrop: 0,
        workingPlaneHeight: 0.7,
        maintenanceFactor: 0.8
    },
    atStart: true,
    atEnd: false,
};

const DefaultSelectedSolution: ProjectResult = {
    id: "",
    projectInputId: "",
    luminaireId: "",
    dateCreated: new Date(),
    luminaire: {
        id: "",
        manufactureName: "",
        dateCreated: new Date(),
        luminaireName : "",
        luminaireColourTemperature: CCTTemperatureType.None,
        luminaireColourType: CCTType.None,
        luminaireRecessedSize: RecessedSizeType.None,
        luminaireControlType: ControlType.None,
        luminaireHousingColour: HousingColourType.None,
        luminaireHousingFinish: HousingFinishType.None,
        luminaireProductType: ProductType.None,
        lengthMM: 0.0,
        heightMM:  0.0,
        widthMM: 0.0,
        deliveredLumens: 200,
        uniformity: 0,
        weightKG: 0.0,
        driver: "",
        ipRating: "",
        ikRating: "",
        mount: "",
    },
    projectInput: {
        id: "",
        projectId: "",
        dateCreated: new Date(),
        buildingType: BuildingType.None,
        commercialOfficeType: CommercialOfficeType.None,
        luminaireProductType: ProductType.None,
        ceilingGridSize: RecessedSizeType.None,
        luminaireControlType: ControlType.None,
        luminaireCorrelatedColourTemperatureType: CCTType.None,
        luminaireColourTemperature: CCTTemperatureType.None,
        luminaireHousingColour: HousingColourType.None,
        luminaireHousingFinish: HousingFinishType.None,
        height: 0,
        width: 0,
        length: 0,
        reflectanceCeiling: 70,
        reflectanceWall: 50,
        reflectanceFloor: 20,
        targetIlluminance: 500,
        ceilingDrop: 0,
        workingPlaneHeight: 0.7,
        maintenanceFactor: 0.8,
    },
    numberRequired: 0,
    luxLevel: 0,
    availability: "",
    rowsRequired: 0,
    columnsRequired: 0,
    wattagePerArea: 0,
    totalSchemeWatts: 0,
    luminaireEfficacy: 0
};

const Home = (): React.ReactElement => {
    const path = window.location.href.replace(/\/+$/, '');
    
    const [selectedSolution, setSelectedSolution] = useState<ProjectResult>(DefaultSelectedSolution);
    const [designSelections, setDesignSelections] = useState<DesignSelections>(DefaultDesignSelections);

    useEffect(() => {
        designSelections.projectName ? document.title = "Lighting Selector - " + designSelections.projectName : document.title = "Lighting Selector";
    }, [designSelections.projectName]);

    let fetchNext = function (designSelections: DesignSelections): void {
        new DesignSelectionClient(path, undefined).postNextCard(designSelections).then((nextDesignSelections: DesignSelections) => {
            console.log('response!!! ' + JSON.stringify(nextDesignSelections));
            setDesignSelections(nextDesignSelections);
        })
    };

    let fetchPrevious = function (designSelections: DesignSelections): void {
        new DesignSelectionClient(path, undefined).postPreviousCard(designSelections).then((nextDesignSelections: DesignSelections) => {
            setDesignSelections(nextDesignSelections);
        })
    };

    let fetchOptimise = function (designSelections: DesignSelections): void {
        new DesignSelectionClient(path, undefined).postOptimise(designSelections).then((nextDesignSelections: DesignSelections) => {
            setDesignSelections(nextDesignSelections);
        })
    }

    useEffect(() => {
        if (designSelections.languageType === LanguageType.None) {
            return;
        }

        designSelections.selection = CardType.LanguageType;
        fetchNext(designSelections);
    }, [designSelections.languageType]);

    useEffect(() => {
        if (designSelections.projectName === "" || designSelections.projectName === undefined || designSelections.projectName === null) {
            return;
        }
        console.log(designSelections.projectName)
        designSelections.selection = CardType.ProjectType;
        fetchNext(designSelections);
    }, [designSelections.projectName]);

    useEffect(() => {
        if (designSelections.buildingType === BuildingType.None) {
            return;
        }

        designSelections.selection = CardType.BuildingType;
        fetchNext(designSelections);
    }, [designSelections.buildingType]);

    useEffect(() => {
        if (designSelections.commercialOfficeType === CommercialOfficeType.None) {
            return;
        }

        designSelections.selection = CardType.CommercialOfficeType;
        fetchNext(designSelections);
    }, [designSelections.commercialOfficeType]);

    useEffect(() => {
        if (designSelections.productType === ProductType.None) {
            return;
        }

        designSelections.selection = CardType.ProductType;
        fetchNext(designSelections);
    }, [designSelections.productType]);

    useEffect(() => {
        if (designSelections.recessedSizeType === RecessedSizeType.None) {
            return;
        }

        designSelections.selection = CardType.RecessedSizeType;
        fetchNext(designSelections);
    }, [designSelections.recessedSizeType]);

    useEffect(() => {
        if (designSelections.controlType === ControlType.None) {
            return;
        }

        designSelections.selection = CardType.ControlType;
        fetchNext(designSelections);
    }, [designSelections.controlType]);

    useEffect(() => {
        if (designSelections.cctType === CCTType.None) {
            return;
        }

        designSelections.selection = CardType.CCTType;
        fetchNext(designSelections);
    }, [designSelections.cctType]);

    useEffect(() => {
        if (designSelections.cctTemperatureType === CCTTemperatureType.None) {
            return;
        }

        designSelections.selection = CardType.CCTTemperatureType;
        fetchNext(designSelections);
    }, [designSelections.cctTemperatureType]);

    useEffect(() => {
        console.log("dynamic type:" + designSelections.cctDynamicType);
        if (designSelections.cctDynamicType === undefined ||designSelections.cctDynamicType === null || designSelections.cctDynamicType?.length === 0) {
            return;
        }

        designSelections.selection = CardType.CCTDynamicType;
    }, [designSelections.cctDynamicType]);

    useEffect(() => {
        if (designSelections.housingColourType === HousingColourType.None) {
            return;
        }

        designSelections.selection = CardType.HousingColourType;
        fetchNext(designSelections);
    }, [designSelections.housingColourType]);

    useEffect(() => {
        if (designSelections.housingFinishType === HousingFinishType.None) {
            return;
        }

        designSelections.selection = CardType.HousingFinishType;
        fetchNext(designSelections);
    }, [designSelections.housingFinishType]);

    useEffect(() => {
        if (selectedSolution && selectedSolution.luminaire?.luminaireName === "") {
            return;
        }

        designSelections.solution = selectedSolution;
        designSelections.selection = CardType.ResultsCard;
        fetchNext(designSelections);
    }, [selectedSolution]);

    useEffect(() => {
        if (designSelections.selection === CardType.CCTDynamicType) {
            return;
        }

        console.log("I'm testing logic!!!!!");
    }, [designSelections.selection]);

    return (
      <div className="mw-75 mt-5">
            <div className="d-block">
                {designSelections.atStart && (
                    <LanguageTypeCard
                        designSelections={designSelections}
                        setDesignSelections={setDesignSelections}
                    />
                )}
                {designSelections.selection === CardType.ProjectType && (
                    <ProjectTypeCard
                        designSelections={designSelections}
                        setDesignSelections={setDesignSelections}
                    />
                )}
                {designSelections.selection === CardType.BuildingType && (
                    <BuildingTypeCard
                        designSelections={designSelections}
                        setDesignSelections={setDesignSelections}
                    />
                )}
                {designSelections.selection === CardType.CommercialOfficeType && (
                    <CommercialOfficeTypeCard
                        designSelections={designSelections}
                        setDesignSelections={setDesignSelections}
                    />
                )}
                {designSelections.selection === CardType.ProductType && (
                    <ProductTypeCard
                        designSelections={designSelections}
                        setDesignSelections={setDesignSelections}
                    />
                )}
                {designSelections.selection === CardType.RecessedSizeType && (
                    <RecessedSizeTypeCard
                        designSelections={designSelections}
                        setDesignSelections={setDesignSelections}
                    />
                )}
                {designSelections.selection === CardType.ControlType && (
                    <ControlTypeCard
                        designSelections={designSelections}
                        setDesignSelections={setDesignSelections}
                    />
                )}
                {designSelections.selection === CardType.CCTType && (
                    <CCTTypeCard
                        designSelections={designSelections}
                        setDesignSelections={setDesignSelections}
                    />
                )}
                {designSelections.selection === CardType.CCTTemperatureType && (
                    <CCTTemperatureTypeCard
                        designSelections={designSelections}
                        setDesignSelections={setDesignSelections}
                    />
                )}
                {designSelections.selection === CardType.CCTDynamicType && (
                    <CCTDynamicTypeCard
                        designSelections={designSelections}
                        setDesignSelections={setDesignSelections}
                    />
                )}
                {designSelections.selection === CardType.HousingColourType && (
                    <HousingColourCard
                        designSelections={designSelections}
                        setDesignSelections={setDesignSelections}
                    />
                )}
                {designSelections.selection === CardType.HousingFinishType && (
                    <HousingFinishCard
                        designSelections={designSelections}
                        setDesignSelections={setDesignSelections}
                    />
                )}
                {designSelections.selection === CardType.RoomDimensionType && (
                    <RoomDimensionCard
                        designSelections={designSelections}
                        setDesignSelections={setDesignSelections}
                        defaultDesignSelections={DefaultDesignSelections}
                        fetchOptimise={fetchOptimise}
                    />
                )}
                {designSelections.selection === CardType.ResultsCard && (
                    <ResultsCard
                        designSelections={designSelections}
                        setSelectedSolution={setSelectedSolution}
                    />
                )}
                {designSelections.selection === CardType.ResultSelectionCard && (
                    <ResultSelectionCard
                        designSelections={designSelections}
                    />
                )}
            </div>
            <div className="d-flex justify-content-end mt-5 mb-5">
                {!designSelections.atStart && (
                    <Button variant="contained" onClick={() => fetchPrevious(designSelections)}>Back</Button>
                )}
                {!designSelections.atEnd && (
                    <Button className="ml-1" variant="contained" onClick={() => fetchNext(designSelections)}>Next</Button>
                )}
            </div>
      </div>
    );
}

export default Home;
