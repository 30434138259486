import React from 'react';
import { PaperNoIcon } from '../Paper';
import { CommercialOfficeType, DesignSelections } from '../../types';

type Props = {
    designSelections: DesignSelections;
    setDesignSelections: React.Dispatch<React.SetStateAction<DesignSelections>>;
}

const CommercialOfficeCard = (props: Props): React.ReactElement => {
    const handleClick = (commercialOfficeType: CommercialOfficeType): void => {
        var newDesignSelections = { ...props.designSelections };
        newDesignSelections.commercialOfficeType = commercialOfficeType;
        props.setDesignSelections(newDesignSelections);
    }

    return (
        <div>
            <h2 className="text-center pb-3">Commercial Office Type</h2>
            <div className="d-flex flex-wrap justify-content-center">
                <PaperNoIcon disabled={false} selected={props.designSelections.commercialOfficeType === CommercialOfficeType.GeneralOffice} onClick={() => handleClick(CommercialOfficeType.GeneralOffice)} header="General Office" />
                <PaperNoIcon disabled={false} selected={props.designSelections.commercialOfficeType === CommercialOfficeType.ComputerWorkstation} onClick={() => handleClick(CommercialOfficeType.ComputerWorkstation)} header="Computer Workstation" />
                <PaperNoIcon disabled={false} selected={props.designSelections.commercialOfficeType === CommercialOfficeType.DrawingOffice} onClick={() => handleClick(CommercialOfficeType.DrawingOffice)} header="Drawing Office" />
            </div>
            <div className="d-flex flex-wrap justify-content-center">
                <PaperNoIcon disabled={false} selected={props.designSelections.commercialOfficeType === CommercialOfficeType.PrintRoom} onClick={() => handleClick(CommercialOfficeType.PrintRoom)} header="Print Room" />
                <PaperNoIcon disabled={false} selected={props.designSelections.commercialOfficeType === CommercialOfficeType.FilingArchiveRoom} onClick={() => handleClick(CommercialOfficeType.FilingArchiveRoom)} header="Filing/Archive Room" />
            </div>
        </div>
    );
}

export default CommercialOfficeCard;
