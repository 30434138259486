import React from 'react';
import { PaperNoIcon } from '../Paper';
import { DesignSelections, RecessedSizeType } from '../../types';

type Props = {
    designSelections: DesignSelections;
    setDesignSelections: React.Dispatch<React.SetStateAction<DesignSelections>>;
}

const CCTTypeCard = (props: Props): React.ReactElement => {

    const handleClick = (recessedSizeType: RecessedSizeType): void => {
        var newDesignSelections = { ...props.designSelections };
        newDesignSelections.recessedSizeType = recessedSizeType;
        props.setDesignSelections(newDesignSelections);
    }

    return (
        <div>
            <h2 className="text-center pb-3">Ceiling Grid Dimensions</h2>
            <div className="d-flex flex-wrap justify-content-center">
                <PaperNoIcon disabled={!props.designSelections.availableRecessedTypes.includes(RecessedSizeType.S600x600)} selected={props.designSelections.recessedSizeType === RecessedSizeType.S600x600} onClick={() => handleClick(RecessedSizeType.S600x600)} header="600mm x 600mm" />
                <PaperNoIcon disabled={!props.designSelections.availableRecessedTypes.includes(RecessedSizeType.S1200x600)} selected={props.designSelections.recessedSizeType === RecessedSizeType.S1200x600} onClick={() => handleClick(RecessedSizeType.S1200x600)} header="1200mm x 600mm" />
                <PaperNoIcon disabled={!props.designSelections.availableRecessedTypes.includes(RecessedSizeType.S1200x300)} selected={props.designSelections.recessedSizeType === RecessedSizeType.S1200x300} onClick={() => handleClick(RecessedSizeType.S1200x300)} header="1200mm x 300mm" />
            </div>
        </div>
    );
}

export default CCTTypeCard;
