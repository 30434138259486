import React from 'react';
import { PaperNoIcon } from '../Paper';
import { DesignSelections, HousingColourType } from '../../types';

type Props = {
    designSelections: DesignSelections;
    setDesignSelections: React.Dispatch<React.SetStateAction<DesignSelections>>;
}

const HousingColourCard = (props: Props): React.ReactElement => {

    const handleClick = (housingColour: HousingColourType): void => {
        var newDesignSelections = { ...props.designSelections };
        newDesignSelections.housingColourType = housingColour;
        props.setDesignSelections(newDesignSelections);
    }

    return (
        <div>
            <h2 className="text-center pb-3">Housing Colour</h2>
            <div className="d-flex flex-wrap justify-content-center pb-3">
                <PaperNoIcon disabled={!props.designSelections.availableHousingColours.includes(HousingColourType.White)} selected={props.designSelections.housingColourType === HousingColourType.White} onClick={() => handleClick(HousingColourType.White)} header="White" />
                <PaperNoIcon disabled={!props.designSelections.availableHousingColours.includes(HousingColourType.Black)} selected={props.designSelections.housingColourType === HousingColourType.Black} onClick={() => handleClick(HousingColourType.Black)} header="Black" />
                <PaperNoIcon disabled={!props.designSelections.availableHousingColours.includes(HousingColourType.Other)} selected={props.designSelections.housingColourType === HousingColourType.Other} onClick={() => handleClick(HousingColourType.Other)} header="Other" />
            </div>
        </div>
    );
}

export default HousingColourCard;
