import React from 'react';
import { PaperNoIcon } from '../Paper';
import { CCTType, DesignSelections } from '../../types';

type Props = {
    designSelections: DesignSelections;
    setDesignSelections: React.Dispatch<React.SetStateAction<DesignSelections>>;
}

const CCTTypeCard = (props: Props): React.ReactElement => {

    const handleClick = (cctType: CCTType): void => {
        var newDesignSelections = { ...props.designSelections };
        newDesignSelections.cctType = cctType;
        props.setDesignSelections(newDesignSelections);
    }

    return (
        <div>
            <h2 className="text-center pb-3">Correlated Colour Temperature</h2>
            <div className="d-flex flex-wrap justify-content-center">
                <PaperNoIcon disabled={false} selected={props.designSelections.cctType === CCTType.Static} onClick={() => handleClick(CCTType.Static)} header="Static" />
                <PaperNoIcon disabled={false} selected={props.designSelections.cctType === CCTType.DynamicWhite} onClick={() => handleClick(CCTType.DynamicWhite)} header="Dynamic White" />
                <PaperNoIcon disabled={!props.designSelections.availableCCTTypes.includes(CCTType.WarmDimming)} selected={props.designSelections.cctType === CCTType.WarmDimming} onClick={() => handleClick(CCTType.WarmDimming)} header="Warm Dimming" />
            </div>
        </div>
    );
}

export default CCTTypeCard;
