import React from 'react';
import { PaperNoIcon } from '../Paper';
import { DesignSelections, ProductType } from '../../types';

type Props = {
    designSelections: DesignSelections;
    setDesignSelections: React.Dispatch<React.SetStateAction<DesignSelections>>;
}

const ProductTypeCard = (props: Props): React.ReactElement => {

    const handleClick = (productType: ProductType): void => {
        var newDesignSelections = { ...props.designSelections };
        newDesignSelections.productType = productType;
        props.setDesignSelections(newDesignSelections);
    }

    return (
        <div>
            <h2 className="text-center pb-3">Product Type</h2>
            <div className="d-flex flex-wrap justify-content-center">
                <PaperNoIcon disabled={!props.designSelections.availableProductTypes.includes(ProductType.Recessed)} selected={props.designSelections.productType === ProductType.Recessed} onClick={() => handleClick(ProductType.Recessed)} header="Recessed" />
                <PaperNoIcon disabled={!props.designSelections.availableProductTypes.includes(ProductType.Surface)} selected={props.designSelections.productType === ProductType.Surface} onClick={() => handleClick(ProductType.Surface)} header="Surface" />
                <PaperNoIcon disabled={!props.designSelections.availableProductTypes.includes(ProductType.Suspended)} selected={props.designSelections.productType === ProductType.Suspended} onClick={() => handleClick(ProductType.Suspended)} header="Suspended" />
            </div>
            <div className="d-flex flex-wrap justify-content-center">
                <PaperNoIcon disabled={!props.designSelections.availableProductTypes.includes(ProductType.Downlights)} selected={props.designSelections.productType === ProductType.Downlights} onClick={() => handleClick(ProductType.Downlights)} header="Downlights" />
                <PaperNoIcon disabled={!props.designSelections.availableProductTypes.includes(ProductType.Industrial)} selected={props.designSelections.productType === ProductType.Industrial} onClick={() => handleClick(ProductType.Industrial)} header="Industrial" />
            </div>
        </div>
    );
}

export default ProductTypeCard;
