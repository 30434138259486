import React, { Component, useEffect, useState } from 'react';
import { Navbar, NavbarBrand } from 'reactstrap';
import { Link, NavLink } from 'react-router-dom';
import './NavMenu.css';
import { Client, ClientClient } from '../types';

type Props = {
    logoPath: string,
    primaryColour: string,
    secondaryColour: string
}

const NavMenu = (props: Props): React.ReactElement => {
    return (
      <header>
            <Navbar className="navbar-expand-sm border-bottom box-shadow mb-3" style={{ backgroundColor: `${props.secondaryColour}` }}>
                <NavbarBrand tag={Link} to="/"><img src={props.logoPath} alt="Logo" className="logo" /></NavbarBrand>
                <h1 style={{ color: `${props.primaryColour}` }} className="text-left mr-auto my-1">Lighting Selector</h1>
        </Navbar>
      </header>
    );
}

export default NavMenu;
