import React from 'react';
import { TextField } from '@mui/material';
import { DesignSelections, PDFClient } from '../../types';
import { Button } from '@mui/material';

type Props = {
    designSelections: DesignSelections;
    setShowPdfSender: React.Dispatch<React.SetStateAction<boolean>>
}

const PDFSenderCard = (props: Props): React.ReactElement => {
    const [email, setEmail] = React.useState("");
    const path = window.location.href.replace(/\/+$/, '');

    let sendEmail = function (): void {
        new PDFClient(path, undefined).generatePDF(props.designSelections, email);
    }

    return (
        <div className="">
            <h2 className="text-center pb-3">Email PDF</h2>

            <div className="d-flex justify-content-center">
                <TextField id="emailAddress" className="mr-2 mb-4" label="Enter email address" variant="outlined" type="text" style={{minWidth: "300px"}} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className="d-flex justify-content-center">
                <Button variant="contained" onClick={() => { sendEmail(); props.setShowPdfSender(false)}}>Send Email</Button>
            </div>
        </div>
    );
}

export default PDFSenderCard;
