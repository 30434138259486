import React from 'react';
import { PaperNoIcon } from '../Paper';
import { CCTTemperatureType, DesignSelections } from '../../types';

type Props = {
    designSelections: DesignSelections;
    setDesignSelections: React.Dispatch<React.SetStateAction<DesignSelections>>;
}

const CCTTemperatureTypeCard = (props: Props): React.ReactElement => {

    const handleClick = (temperatureType: CCTTemperatureType): void => {
        var newDesignSelections = { ...props.designSelections };
        newDesignSelections.cctTemperatureType = temperatureType;
        props.setDesignSelections(newDesignSelections);
    }

    return (
        <div>
            <h2 className="text-center pb-3">Correlated Colour Temperature</h2>
            <div className="d-flex flex-wrap justify-content-center">
                <PaperNoIcon disabled={!props.designSelections.availableColourTemperatures.includes(CCTTemperatureType.K2700)} selected={props.designSelections.cctTemperatureType === CCTTemperatureType.K2700} onClick={() => handleClick(CCTTemperatureType.K2700)} header="2700K" />
                <PaperNoIcon disabled={!props.designSelections.availableColourTemperatures.includes(CCTTemperatureType.K3000)} selected={props.designSelections.cctTemperatureType === CCTTemperatureType.K3000} onClick={() => handleClick(CCTTemperatureType.K3000)} header="3000K" />
                <PaperNoIcon disabled={!props.designSelections.availableColourTemperatures.includes(CCTTemperatureType.K3500)} selected={props.designSelections.cctTemperatureType === CCTTemperatureType.K3500} onClick={() => handleClick(CCTTemperatureType.K3500)} header="3500K" />
            </div>
            <div className="d-flex flex-wrap justify-content-center">
                <PaperNoIcon disabled={!props.designSelections.availableColourTemperatures.includes(CCTTemperatureType.K4000)} selected={props.designSelections.cctTemperatureType === CCTTemperatureType.K4000}  onClick={() => handleClick(CCTTemperatureType.K4000)} header="4000K" />
                <PaperNoIcon disabled={!props.designSelections.availableColourTemperatures.includes(CCTTemperatureType.K5000)} selected={props.designSelections.cctTemperatureType === CCTTemperatureType.K5000} onClick={() => handleClick(CCTTemperatureType.K5000)} header="5000K" />
            </div>
        </div>
    );
}

export default CCTTemperatureTypeCard;
