import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

interface CardProps {
    icon?: JSX.Element;
    picture?: JSX.Element;
    header: string;
    onClick: () => void;
    disabled: boolean;
    selected: boolean;
}

export const PaperWithIcon = (props: CardProps): JSX.Element => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
            }}
            className={"paper-selector paper-with-icon"}
        >
            {props.disabled ?
                <Paper style={{ color: '#cccccc' }} elevation={3}><h3 className="text-center">{props.header}</h3>
                    <div id="cardIcon" className="text-center">
                        {props.icon}
                    </div>
                </Paper>
                :
                props.selected ?
                    <Paper style={{ backgroundColor: '#f5f5f5', border: '4px solid' }} onClick={props.onClick} elevation={3}><h3 className="text-center">{props.header}</h3>
                        <div id="cardIcon" className="text-center">
                                {props.icon}
                        </div>
                    </Paper>
                    :
                    <Paper onClick={props.onClick} elevation={3}><h3 className="text-center">{props.header}</h3>
                        <div id="cardIcon" className="text-center">
                            {props.icon}
                        </div>
                    </Paper>
            }

        </Box>
    );
}

export const PaperWithPicture = (props: CardProps): JSX.Element => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
            }}
            className={"paper-selector paper-with-img"}
        >
            {props.disabled ?
                <Paper style={{ color: '#cccccc' }} elevation={3}><h3 className="text-center">{props.header}</h3>
                    <div className="text-center paper-picture">
                        {props.picture}
                    </div>
                </Paper>
                :
                props.selected ?
                    <Paper onClick={props.onClick} style={{ backgroundColor: '#f5f5f5', border: '4px solid' }} elevation={3}><h3 className="text-center">{props.header}</h3>
                        <div className="text-center paper-picture">
                            {props.picture}
                        </div>
                    </Paper>
                    :
                    <Paper onClick={props.onClick} elevation={3}><h3 className="text-center">{props.header}</h3>
                        <div className="text-center paper-picture">
                            {props.picture}
                        </div>
                    </Paper>
            }
        </Box>
    );
}

export const PaperNoIcon = (props: CardProps): JSX.Element => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
            }}
            className={"paper-selector"}
        >
            {props.disabled ?
                <Paper style={{ color: '#cccccc' }} elevation={3}><h3 className="text-center">{props.header}</h3>
                </Paper>
                :
                props.selected ?
                    <Paper onClick={props.onClick} style={{ backgroundColor: '#f5f5f5', border: '4px solid' }} elevation={3}><h3 className="text-center">{props.header}</h3>
                    </Paper>
                    :
                    <Paper onClick={props.onClick} elevation={3}><h3 className="text-center">{props.header}</h3>
                    </Paper>
            }
        </Box>
    );
}

export default PaperWithIcon;