import React from 'react';
import { TextField } from '@mui/material';
import { DesignSelections } from '../../types';

type Props = {
    designSelections: DesignSelections;
    setDesignSelections: React.Dispatch<React.SetStateAction<DesignSelections>>;
}

const ProjectTypeCard = (props: Props): React.ReactElement => {
    const setProjectName = (projectName: string): void => {
        var newDesignSelections = { ...props.designSelections };
        newDesignSelections.projectName = projectName;
        props.setDesignSelections(newDesignSelections);
    }

    return (
        <div>
            <h2 className="text-center pb-3">Project Name</h2>
            <div className="d-flex flex-wrap justify-content-center">
                <TextField id="projectName" label="Project Name" variant="outlined" type="text" style={{ width: 350 }} defaultValue={props.designSelections.projectName} onBlur={(e) => setProjectName(e.target.value)} />
            </div>
        </div>
    );
}

export default ProjectTypeCard;
