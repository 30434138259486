import React, { useEffect, useState } from 'react';
import { DesignSelections, Project, ProjectClient, ProjectResult } from '../types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button, TableContainer } from '@mui/material';
import Paper from '@mui/material/Paper';
import ProjectLuminaire from './ProjectLuminaire';

type Props = {
    projectId: string,
}

type RowProps = {
    row: ProjectResult,
    setSelectedRow: React.Dispatch<React.SetStateAction<string>>
}

function Row(props: RowProps) {
    const row = props.row;
    const [open, setOpen] = React.useState<boolean>(false);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell className="pt-0 pb-0 pl-0 pr-0" width="5%">
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={
                            () => {
                                setOpen(!open);
                            }
                        }
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" width="30%">
                    {row.luminaire.manufactureName}
                </TableCell>
                <TableCell width="30%">{row.luminaire.luminaireName}</TableCell>
                <TableCell width="25%">{row.totalSchemeWatts}W</TableCell>
                <TableCell width="10%"><Button variant="contained" onClick={() => { row.luminaire.luminaireName && props.setSelectedRow(row.luminaire.luminaireName) }}>View More</Button></TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={5}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table size="small" aria-label="details" style={{ marginBottom: 10, marginTop: 10, borderBottom: "none" }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="5%" />
                                        <TableCell width="30%">Availability</TableCell>
                                        <TableCell width="30%">Wattage per m&sup2;</TableCell>
                                        <TableCell width="25%">Number Required</TableCell>
                                        <TableCell width="10%" />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell width="5%" />
                                        <TableCell width="30%">{row.availability}</TableCell>
                                        <TableCell width="30%">{row.wattagePerArea} W/m&sup2;</TableCell>
                                        <TableCell width="25%">{row.numberRequired}</TableCell>
                                        <TableCell width="10%" />
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Table size="small" aria-label="room details" style={{ marginBottom: 10, marginTop: 10, border: "none" }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="5%" />
                                        <TableCell width="30%">Rows</TableCell>
                                        <TableCell width="30%">Columns</TableCell>
                                        <TableCell width="25%">Lux Level</TableCell>
                                        <TableCell width="10%" />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell width="5%" />
                                        <TableCell width="30%">{row.rowsRequired}</TableCell>
                                        <TableCell width="30%">{row.columnsRequired}</TableCell>
                                        <TableCell width="25%">{row.luxLevel}</TableCell>
                                        <TableCell width="10%" />
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function CollapsibleTable(props: Props) {
    const path = window.location.href.replace(/\/+$/, '');
    const [selectedRow, setSelectedRow] = React.useState<string>('');
    //const [project, setProject] = useState<Project>(null);
    const [designSelections, setDesignSelections] = useState<DesignSelections>(null);
    const [showLuminaire, setShowLuminaire] = useState<boolean>(false);

    //useEffect(() => {
    //    project.projectName ? document.title = "Lighting Selector - " + project.projectName : document.title = "Lighting Selector";
    //}, [project.projectName]);

    //let fetchProject = function (): void {
    //    new ProjectClient(path, undefined).get(props.projectId).then((project: Project) => {
    //        setProject(project);
    //    })
    //};

    let fetchDesignSelections = function (id: string): void {
        new ProjectClient(path, undefined).get(id).then((designSelections: DesignSelections) => {
            setDesignSelections(designSelections);
        })
    };

    useEffect(() => {
        fetchDesignSelections(props.projectId);
    }, [props.projectId]);

    useEffect(() => {
        if (selectedRow === "") {
            return;
        }

        var selected = designSelections.solutions?.find(x => x.luminaire.luminaireName === selectedRow);

        if (selected && selected.luminaire.luminaireName !== undefined) {
            designSelections.solution = selected;
            setDesignSelections(designSelections);
            setShowLuminaire(true);
        }

    }, [selectedRow]);

    return (
        <div>
            <div>
                {designSelections && designSelections.solutions && designSelections.solutions.length > 0 && !showLuminaire && (
                    <div>
                    <h2 className="text-center pb-3">Your Results</h2>
                        <TableContainer component={Paper}>
                            <Table aria-label="collapsible table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="5%" />
                                        <TableCell width="30%">Manufacturer</TableCell>
                                        <TableCell width="30%">Product</TableCell>
                                        <TableCell width="25%">Total Scheme Watts</TableCell>
                                        <TableCell width="10%" />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {designSelections.solutions.map((row) => (
                                         <Row key={row.id} row={row} setSelectedRow={setSelectedRow} />
                                    ))}
                                </TableBody>
                            </Table>
                            </TableContainer>
                        </div>
                )}
                {showLuminaire && designSelections.solution && (
                    <ProjectLuminaire
                        designSelections={designSelections}
                        setShowLuminaire={setShowLuminaire}
                    />
                )}
             </div>
        </div>
    );
}