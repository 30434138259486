import React, { Component } from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';

type Props = {
    logoPath: string,
    primaryColour: string,
    secondaryColour: string
}

export class Layout extends Component<Props> {
  static displayName = Layout.name;

  render () {
    return (
      <div>
            <NavMenu logoPath={this.props.logoPath} primaryColour={this.props.primaryColour} secondaryColour={this.props.secondaryColour} />
        <Container>
          {this.props.children}
        </Container>
      </div>
    );
  }
}
