import React from 'react';
import { PaperWithIcon } from '../Paper';
import { Business, School, ChairAlt, Garage, Hotel, Handyman } from '@mui/icons-material';
import { BuildingType, DesignSelections } from '../../types';

type Props = {
    designSelections: DesignSelections;
    setDesignSelections: React.Dispatch<React.SetStateAction<DesignSelections>>;
}

const BuildingTypeCard = (props: Props): React.ReactElement => {

    const handleClick = (buildingType: BuildingType): void => {
        var newDesignSelections = { ...props.designSelections };
        newDesignSelections.buildingType = buildingType;
        props.setDesignSelections(newDesignSelections);
    }

    return (
        <div>
            <h2 className="text-center pb-3">Building Type</h2>
            <div className="d-flex flex-wrap justify-content-center">
                <PaperWithIcon disabled={false} selected={props.designSelections.buildingType === BuildingType.CommercialOffices} onClick={() => handleClick(BuildingType.CommercialOffices)} icon={<Business className="icon" />} header="Commercial Office" />
                <PaperWithIcon disabled={false} selected={props.designSelections.buildingType === BuildingType.Hospitality} onClick={() => handleClick(BuildingType.Hospitality)} icon={<Hotel className="icon" />} header="Hospitality" />
                <PaperWithIcon disabled={false} selected={props.designSelections.buildingType === BuildingType.Education} onClick={() => handleClick(BuildingType.Education)} icon={<School className="icon" />} header="Education" />
            </div>
            <div className="d-flex flex-wrap justify-content-center">
                <PaperWithIcon disabled={false} selected={props.designSelections.buildingType === BuildingType.Ancillary} onClick={() => handleClick(BuildingType.Ancillary)} icon={<Garage className="icon" />} header="Ancillary" />
                <PaperWithIcon disabled={false} selected={props.designSelections.buildingType === BuildingType.Warehouse} onClick={() => handleClick(BuildingType.Warehouse)} icon={<Handyman className="icon" />} header="Warehouse" />
                <PaperWithIcon disabled={false} selected={props.designSelections.buildingType === BuildingType.Workshop} onClick={() => handleClick(BuildingType.Workshop)} icon={<ChairAlt className="icon" />} header="Workshop" />
            </div>
        </div>
    );
}

export default BuildingTypeCard;
