import React from 'react';
import { PaperWithPicture } from '../Paper';
import { DesignSelections, LanguageType } from '../../types';
import EnglishFlag from '../../images/English.png'
import FrenchFlag from '../../images/French.jpg'
import GermanFlag from '../../images/German.jpg'

type Props = {
    designSelections: DesignSelections;
    setDesignSelections: React.Dispatch<React.SetStateAction<DesignSelections>>;
}

const LanguageTypeCard = (props: Props): React.ReactElement => {

    const handleClick = (languageType: LanguageType): void => {
        var newDesignSelections = { ...props.designSelections };
        newDesignSelections.languageType = languageType;
        props.setDesignSelections(newDesignSelections);
    }

    return (
        <div>
            <h2 className="text-center pb-3">Language</h2>
            <div className="d-flex flex-wrap justify-content-center">
                <PaperWithPicture disabled={false} selected={props.designSelections.languageType === LanguageType.English} onClick={() => handleClick(LanguageType.English)} picture={<img src={EnglishFlag} alt="English" />} header="English" />
                <PaperWithPicture disabled={false} selected={props.designSelections.languageType === LanguageType.French} onClick={() => handleClick(LanguageType.French)} picture={<img src={FrenchFlag} alt="French" />} header="French" />
                <PaperWithPicture disabled={false} selected={props.designSelections.languageType === LanguageType.German} onClick={() => handleClick(LanguageType.German)} picture={<img src={GermanFlag} alt="German" />} header="German" />
            </div>
        </div>
    );
}

export default LanguageTypeCard;
