import React, { useState } from 'react';
import { Button, Switch, TextField } from '@mui/material';
import { DesignSelections } from '../../types';

type Props = {
    designSelections: DesignSelections;
    setDesignSelections: React.Dispatch<React.SetStateAction<DesignSelections>>;
    defaultDesignSelections: any;
    fetchOptimise: any;
}

const RoomDimensionCard = (props: Props): React.ReactElement => {
    const [disableOptionalSettings, setDisableOptionalSettings] = React.useState(false);

    const [height, setHeight] = useState<number>(props.designSelections.height);
    const [width, setWidth] = useState<number>(props.designSelections.width);
    const [length, setLength] = useState<number>(props.designSelections.length);
    const [workingPlaneHeight, setWorkingPlaneHeight] = useState<number>(props.designSelections.roomProperties.workingPlaneHeight);
    const [ceilingDrop, setCeilingDrop] = useState<number>(props.designSelections.roomProperties.ceilingDrop);
    const [targetIlluminance, setTargetIlluminance] = useState<number>(props.designSelections.roomProperties.targetIlluminance);
    const [reflectanceCeiling, setReflectanceCeiling] = useState<number>(props.designSelections.roomProperties.reflectanceCeiling);
    const [reflectanceWall, setReflectanceWall] = useState<number>(props.designSelections.roomProperties.reflectanceWall);
    const [reflectanceFloor, setReflectanceFloor] = useState<number>(props.designSelections.roomProperties.reflectanceFloor);
    const [maintenanceFactor, setMaintenanceFactor] = useState<number>(props.designSelections.roomProperties.maintenanceFactor);

    const setDimensions = (): void => {
        if (height === 0 || width === 0 || length === 0) {
            alert("Please enter room dimensions");
            return;
        }

        var newDesignSelections = { ...props.designSelections };
        newDesignSelections.height = height;
        newDesignSelections.width = width;
        newDesignSelections.length = length;
        newDesignSelections.roomProperties.workingPlaneHeight = workingPlaneHeight;
        newDesignSelections.roomProperties.ceilingDrop = ceilingDrop;
        newDesignSelections.roomProperties.targetIlluminance = targetIlluminance;
        newDesignSelections.roomProperties.reflectanceCeiling = reflectanceCeiling;
        newDesignSelections.roomProperties.reflectanceWall = reflectanceWall;
        newDesignSelections.roomProperties.reflectanceFloor = reflectanceFloor;
        newDesignSelections.roomProperties.maintenanceFactor = maintenanceFactor;

        props.setDesignSelections(newDesignSelections);
        props.fetchOptimise(newDesignSelections);
    }

    function handleSwitchChange(e: any) {
        setDisableOptionalSettings(!e.target.checked);
    };

    var optionalText: string;
    if (disableOptionalSettings) {
        optionalText = 'Optional settings (disabled)';
    } else {
        optionalText = 'Optional settings (enabled)';
    };

    return (
        <div>
            <h2 className="text-center pb-3">Room Dimensions</h2>
            <div className="d-flex flex-wrap justify-content-center pb-2">
                <TextField id="height" className="mr-2 mb-4" label="Height (m)" variant="outlined" type="number" inputProps={{ step: "0.1", min: 0 }} defaultValue={props.designSelections.height} onChange={(e) => setHeight(parseFloat(e.target.value))} />
                <TextField id="width" className="mr-2 mb-4" label="Width (m)" variant="outlined" type="number" inputProps={{ step: "0.1", min: 0 }} defaultValue={props.designSelections.width} onChange={(e) => setWidth(parseFloat(e.target.value))} />
                <TextField id="length" className="mr-2 mb-4" label="Length (m)" variant="outlined" type="number" inputProps={{ step: "0.1", min: 0 }} defaultValue={props.designSelections.length} onChange={(e) => setLength(parseFloat(e.target.value))} />
            </div>

            <div className="d-flex flex-wrap justify-content-center">
                {optionalText}
                <Switch
                    checked={!disableOptionalSettings}
                    onChange={handleSwitchChange}
                    color="primary"
                />
            </div>

            <h2 className="text-center pb-3">Calculation Settings</h2>
            <div className="d-flex flex-wrap justify-content-center pb-2">
                <TextField disabled={disableOptionalSettings} id="workingPlaneHeight" className="mr-2 mb-4" label="Working Plane Height (m)" variant="outlined" type="number" inputProps={{ step: "0.1", min: 0 }} defaultValue={props.designSelections.roomProperties.workingPlaneHeight} onChange={(e) => setWorkingPlaneHeight(parseFloat(e.target.value))} />
                <TextField disabled={disableOptionalSettings} id="ceilingDrop" className="mr-2 mb-4" label="Fittings Suspended By (m)" variant="outlined" type="number" inputProps={{ step: "0.1", min: 0 }} defaultValue={props.designSelections.roomProperties.ceilingDrop} onChange={(e) => setCeilingDrop(parseFloat(e.target.value))} />
                <TextField disabled={disableOptionalSettings} id="targetIlluminance" className="mr-2 mb-4" label="Target Illuminance (Lux)" variant="outlined" type="number" inputProps={{ step: "50", min: 0 }} defaultValue={props.designSelections.roomProperties.targetIlluminance} onChange={(e) => setTargetIlluminance(parseFloat(e.target.value))} />
            </div>
            <div className="d-flex flex-wrap justify-content-center pb-2">
                <TextField disabled={disableOptionalSettings} id="reflectanceCeiling" className="mr-2 mb-4" label="Reflectance Ceiling" variant="outlined" type="number" inputProps={{ step: "1", min: 0 }} defaultValue={props.designSelections.roomProperties.reflectanceCeiling} onChange={(e) => setReflectanceCeiling(parseFloat(e.target.value))} />
                <TextField disabled={disableOptionalSettings} id="reflectanceWall" className="mr-2 mb-4" label="Reflectance Walls" variant="outlined" type="number" inputProps={{ step: "1", min: 0 }} defaultValue={props.designSelections.roomProperties.reflectanceWall} onChange={(e) => setReflectanceWall(parseFloat(e.target.value))} />
                <TextField disabled={disableOptionalSettings} id="reflectanceFloor" className="mr-2 mb-4" label="Reflectance Floor" variant="outlined" type="number" inputProps={{ step: "1", min: 0 }} defaultValue={props.designSelections.roomProperties.reflectanceFloor} onChange={(e) => setReflectanceFloor(parseFloat(e.target.value))} />
            </div>
            <div className="d-flex flex-wrap justify-content-center">
                <TextField disabled={disableOptionalSettings} id="maintenanceFactor" className="mr-2 mb-4" label="Maintenance Factor" variant="outlined" type="number" inputProps={{ step: "0.1", min: 0 }} defaultValue={props.designSelections.roomProperties.maintenanceFactor} onChange={(e) => setMaintenanceFactor(parseFloat(e.target.value))} />
            </div>

            <div className="d-flex flex-wrap justify-content-center pt-3">
                <Button variant="contained" onClick={() => {
                    setDimensions();
                }
                }>Optimise</Button>
            </div>
        </div>
    );
}

export default RoomDimensionCard;
