import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import { Project, ProjectClient } from '../types';
import dateFormat from 'dateformat';

type RowProps = {
    row: Project,
    setSelectedRow: React.Dispatch<React.SetStateAction<string>>,
    deleteProject: React.Dispatch<React.SetStateAction<string>>,
}

function Row(props: RowProps) {
    const row = props.row;

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell component="th" scope="row" width="50%">
                    {row.projectName}
                </TableCell>
                <TableCell component="th" scope="row" width="30%">
                    {dateFormat(row.dateCreated, 'dd/mm/yyyy hh:mm')}
                </TableCell>
                <TableCell width="10%"><Button variant="outlined" onClick={() => { row.id && props.deleteProject(row.id) }}>Delete Project</Button></TableCell>
                <TableCell width="10%"><Button variant="contained" onClick={() => { row.id && props.setSelectedRow(row.id) }}>View Results</Button></TableCell>
            </TableRow>
            
        </React.Fragment>
    );
}

export default function CollapsibleTable() {
    const path = window.location.href.replace(/\/+$/, '');
    const [projects, setProjects] = React.useState<Project[]>();
    const [selectedRow, setSelectedRow] = React.useState<string>('');
    const history = useHistory();

    let fetchProjects = function (): void {
        new ProjectClient(path, undefined).getAll().then((projects: Project[]) => {
            setProjects(projects);
        })
    };

    const deleteProject = (id: string) => {
        new ProjectClient(path, undefined).delete(id);
        window.location.reload(false);
    }

    useEffect(() => {
        fetchProjects();
    }, []);

    useEffect(() => {
        if (selectedRow === "") {
            return;
        }

        history.push(`/ProjectResults/${selectedRow}`);

    }, [selectedRow]);

    return (
        <div>
            <h2 className="text-center pb-3">Your Projects</h2>
            <div className="px-5">
                {projects && projects.length > 0 && (
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell width="50%">Project Name</TableCell>
                                    <TableCell width="30%">Created</TableCell>
                                    <TableCell width="10%">Delete</TableCell>
                                    <TableCell width="10%">View</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {projects.map((row) => (
                                    <Row key={row.id} row={row} setSelectedRow={setSelectedRow} deleteProject={deleteProject} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </div>
        </div>
    );
}