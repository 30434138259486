import React, { useEffect, useState } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import Home from './components/Home';
import Projects from './components/Projects';
import ProjectResults from './components/ProjectResults';

import './custom.css'
import { Client, ClientClient } from './types';

function App(): React.ReactElement {
    const displayName = Layout.name;
    const path = window.location.href.replace(/\/+$/, '');

    const [logoPath, setLogoPath] = useState<string>("");
    const [primaryColour, setPrimaryColour] = useState<string>("");
    const [secondaryColour, setSecondaryColour] = useState<string>("");

    useEffect(() => {
        new ClientClient(path, undefined).get().then((client: Client) => {
            setLogoPath(client.logoPath);
            setPrimaryColour(client.primaryColour);
            setSecondaryColour(client.secondaryColour);
        }) // https://stackoverflow.com/questions/40769551/how-to-use-google-fonts-in-react-js
    }, []);

    return (
        <>
            {logoPath !== "" && (
                <Layout logoPath={logoPath} primaryColour={primaryColour} secondaryColour={secondaryColour}>
                    <Route exact path='/' component={Home} />
                    <Route exact path='/Projects' component={Projects} />
                    <Route exact path="/ProjectResults/:id" render={(props) => <ProjectResults projectId={props.match.params.id} />}  />
                </Layout>
            )}
        </>
    );
  }

export default App;
